import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import heroImage from "../assets/london-hero.jpg";
import ReactMarkdown from 'react-markdown';
import StylizedText from "../../src/components/layout/StylizedText";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedService, setExpandedService] = useState(null);

  const services = [
    {
      title: "Accounting & Strategy",
      description: `Partnering with our clients our accounting solutions start with the everyday items, bookkeeping, VAT returns and payroll. This approach gives you back valuable time and peace of mind, ensuring that day-to-day figures are accurate and up-to-date, creating a solid foundation for strategic business review.`,
      details: `
### Adding Strategic Value to Your Business 

Accurate, real-time financial data allows our experienced team to add strategic value by offering management insights and fostering discussions that drive efficiency across your business. We support you at every stage of the business life cycle, including:

Tax strategies: Our experienced, qualified, Chartered Tax Advisers (CTA’s) aim to reduce “tax leakage” from your business, maximising profit and, importantly, preserving wealth 

Financial Modelling: By partnering with you, we develop a clear financial roadmap that incorporates growth forecasts, market volatility, sector trends, cash flow projections, and tax timing. This strategic approach enables confident planning for future growth.

Cash Flow: “Cash is King”, our bespoke cash flow analysis provides real-time data to anticipate and avoid short-term cash constraints while preparing for future expenses and investment opportunities

Strategic Business Advisory : Our team has supported numerous clients with projects such as preparing for sale or management buyouts (MBOs), expanding into new markets, and assessing investment opportunities.

Partnering with Fox Smith gives you access to a wealth of expertise dedicated to supporting the ongoing success of your business.`,
    },
    {
      title: "Tax",
      description: `
Navigating the complex, evolving tax landscape can be especially challenging for business owners and individuals managing significant financial responsibilities
Fox Smith offers cost-effective tax compliance services alongside holistic tax advice.`,
      details: `By reviewing the entire financial footprint, we provide precise guidance that avoids the risks of a piecemeal approach, which can lead to unintended consequences in other areas of the structure or family.

Our team comprises experienced Chartered Tax Advisers and Trust and Estate Practitioners who stay current with the latest tax legislation. We provide proactive advice within the bounds of tax law, without reliance on any "schemes," to maximize your wealth and minimize tax exposure.

### Corporate Tax

Our expertise includes:

### Tax Efficient Investment Structures
Including the creation of groups, reorganisations of existing groups, such as de-mergers, SEIS and EIS applications and valuations.

### Renumeration & Team Strategies
Tailored solutions for incentivizing team members through options or shares, including EMI options and growth shares, designed to align with your business goals

### Family Investment Company and Property Investment Companies
Strategies for managing the complex taxes associated with these entities, including alphabet shares and ATED

### International Expansion
We are able to provide advice for inbound expansion and outbound expansion, leveraging our extensive international network for seamless support across borders

### Personal Tax / Property Investors / HMRC Investigations / Crypto & NFTs

Fox Smith’s holistic approach, ensuring seamless collaboration between our corporate and personal tax specialists. All our tax advisory and compliance is performed in the UK by our team of Chartered Tax Advisers cost effectively, maximising tax efficiencies and reducing the chances of an HMRC enquiry due to inaccuracies. 

In addition to compliance, some of the areas our team offers expert advice on are as follows:

### Property Investors and Tax-Efficient Structures. 

Advice on optimal structures and tax efficiencies for property investments. If you've participated in schemes like those offered by “Property 118,” we can assist with unwinding these arrangements.

### Capital Gains Tax
We offer support on the disposal of assets and identify relevant reliefs to minimise Capital Gains Tax liabilities.

### HMRC enquiries
Receiving a notice from HMRC can be stressful. Our team has extensive experience in managing and resolving HMRC inquiries, helping clients navigate the process with confidence

### Cryptocurrencies & NFTs

With a solid understanding of these emerging markets, we ensure accurate reporting and compliance, helping you meet international tax obligations for cryptocurrencies and NFTs effectively

**Partnering with Fox Smith means gaining a team of tax experts dedicated to optimising your tax position and securing your financial future.**`,
    },
    {
      title: "Inheritance Tax / Trust & Estates / Family Wealth Preservation Structuring",
      description: `Our tax specialists are both Chartered Tax Advisers (CTA) and members of the Society of Trust & Estate Practitioners (STEP), the global accreditation body for professionals helping families with future planning.`,
      details: `
By working with a CTA and regulated Trust and Estate Practitioner, you are assured of receiving top-tier advice tailored to your family’s unique needs..

Our team works closely with you to understand your family dynamics, goals, and concerns, crafting tailored strategies that align with your unique needs. During inheritance tax planning, we explore various solutions, including:

**Trust Structures** discretionary trusts, life interest trusts, Immediate Post-Death Interest (IPDI) trusts, and loan trusts to manage and protect family wealth

**Family Investment Companies (FICs)** Advice on structuring FICs with alphabet shares, freezer shares, flowering shares, and growth shares. For those holding deferred shares, we provide guidance on their efficiency and suitability.

**Lifetime Gifting Strategies** Support with outright gifts or the use of bare trusts for lifetime gifting. If you’re involved in arrangements like a “School Fees Trust,” we offer reviews and recommendations.

**Business Property Relief (BPR) & Agricultural Property Relief (APR)**: Although reduced in the Autumn 2024 budget, opportunities still exist for tax-efficient use of BPR and APR.

**Will Planning**, We review existing Wills and assist in creating tax-efficient Wills, ensuring your legacy is preserved in a manner that minimises tax liabilities

**Trusts & Estates**

Our team serves as both advisors and trustees, where necessary, providing comprehensive support in trust and estate management

**Trust Tax and Compliance**

We offer a full suite of trust compliance services, including:

* Trust Registration and maintenance with HMRC via the Trust Registration Service

* Preparation of Trust Accounts, detailed preparation of accounts to ensure accuracy and compliance for trustee reporting 

* Trust Self-Assessment Tax returns (SA900) including form R185 where required 

* Inheritance Tax calculations and IHT100 form submissions including:

  * 10 Year Charges

  * Entry and Exit Charges 

* Assistance with distributions & tax pools, Guidance on managing distributions and tax implications.

* Winding up Trusts where necessary, ensuring compliance and efficiency

**Estates Compliance**

We recognise that losing a loved one, even when anticipated due to age or illness, is a profoundly distressing experience. 

Our team is here to ease the burden by managing Inheritance Tax Returns (IHT 400) and preparing for probate. We offer fixed, competitive fees for this service to help alleviate the stress during this difficult time.`,
    },
    {
      title: "Non-UK Domiciled Individuals",
      description: `The Autumn 2024 budget has brought significant changes to the tax landscape for UK Resident, Non-UK Domiciled (RND) individuals. At Fox Smith, we have extensive experience advising RNDs on all aspects of international tax.`,
      details: `
**Remittance Basis of Taxation**
* Guidance on how to manage income and gains from overseas sources.

**Overseas Workday Relief**
* Strategies to maximise relief for days worked abroad, minimising UK tax liabilities.

**Double Taxation Treaties**
* Advice on leveraging treaties to avoid being taxed twice on the same income.

**Tax Credits**
* Understanding available credits to reduce tax liabilities.

**Inheritance Tax Excluded Property**
* Assistance with structuring assets to benefit from excluded property status under UK Inheritance Tax laws.

In light of the recent budget changes, many RNDs are reassessing their current structures and future planning. Our team is well-versed in these matters and is prepared to offer tailored advice and strategic solutions to navigate the new tax landscape effectively.`,
    },
    {
      title: "Offshore Trusts",
      description: `Fox Smith serves as tax advisers for several large offshore Trust Companies , primarily based in the Channel Islands. Our team offers comprehensive tax advice and solutions for Settlors, Beneficiaries, and Trustees.`,
      details: `

**Advice on Settling an Offshore Trust**
* Guidance for individuals considering the establishment of an offshore trust, addressing key considerations and potential implications.
      
**Analysis of Transfer of Assets Legislation**
* In-depth analysis to ensure compliance and understanding of the relevant legislation when transferring assets 
      
**Income and Gains Maintenance**
* Advice and support with the calculation and maintenance of relevant income and stockpiled gains
      
**Relevant Property Charges and Non-Resident Capital Gains Tax**
* Calculation and reporting of relevant property charges and  non-resident capital gains tax charges.
      
Recognising the intricacies of offshore structures, we are aware that historical errors may arise or be uncovered. Fox Smith is equipped to assist with the regulation and rectification of these issues.
      
The Autumn 2024 budget has also impacted offshore trusts, and our team is equipped to discuss these changes and develop strategic solutions`,
    },
  ];

  const toggleExpand = (title) => {
    setExpandedService(expandedService === title ? null : title);
  };

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative h-[70vh]">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${heroImage})`,
            filter: "grayscale(100%)",
          }}
        ></div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative h-full flex items-center">
          <div className="container mx-auto px-4 text-white">
            <div className="max-w-3xl">
              <h1 className="text-2xl font-bold mb-6">
                <StylizedText text="Our Services" />
              </h1>
              <p className="text-xl mb-12">
                Comprehensive financial solutions tailored to your needs
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-16">
            {services.map((service, index) => (
              <div
                key={index}
                className="border border-gray-200 p-8 hover:shadow-xl transition duration-300"
              >
                <h2 className="text-2xl font-semibold mb-4">{service.title}</h2>
                <div className="prose max-w-none mb-6">
                  <ReactMarkdown>{service.description}</ReactMarkdown>
                </div>
                {expandedService === service.title && (
                  <div className="mt-4 mb-6">
                    <ReactMarkdown className="prose max-w-none text-gray-700">
                      {service.details}
                    </ReactMarkdown>
                  </div>
                )}
                <button
                  onClick={() => toggleExpand(service.title)}
                  className="text-black font-semibold hover:underline"
                >
                  {expandedService === service.title
                    ? "Show Less"
                    : "Learn More"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-black text-white py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Elevate Your Financial Strategy?
          </h2>
          <p className="text-xl mb-12 max-w-2xl mx-auto">
            Let's discuss how our bespoke services can address your unique
            financial challenges and help you achieve your goals.
          </p>
          <Link
            to="/contact"
            className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold inline-block"
          >
            Enquire Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Services;