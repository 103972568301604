// src/components/legal/CookiePolicy.js
import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Cookie Policy</h1>
      
      <h2 className="text-xl font-semibold mt-6 mb-3">1. What Are Cookies</h2>
      <p className="mb-4">Cookies are small text files that are placed on your computer or mobile device when you visit a website.</p>

      <h2 className="text-xl font-semibold mt-6 mb-3">2. How We Use Cookies</h2>
      <p className="mb-2">We use cookies to:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Understand how you use our website</li>
        <li>Remember your preferences</li>
        <li>Improve your experience on our website</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">3. Contact Us</h2>
      <p className="mb-2">If you have any questions about this Cookie Policy, please contact us at:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Email: privacy@foxsmith.uk</li>
        <li>Phone: 01628 276 130</li>
        <li>Address: 43 High Street, Marlow, SL7 1BA</li>
      </ul>
    </div>
  );
};

export default CookiePolicy;