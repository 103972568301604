import React, { useEffect, useState } from "react";
import { MapPin, Mail, Phone } from "lucide-react";
import SocialMedia from "../components/common/SocialMedia";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phoneNumber: "",
    subject: "",
    serviceRequired: "",
    heardAboutUs: "",
    consent: false,
  });

  const [errors, setErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const TELEGRAM_BOT_TOKEN = "7990844720:AAF-wqqOfr4IdQddalmZ9N8wHABLmuBrsIU";
  const TELEGRAM_CHAT_ID = "1148070115";

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.message) newErrors.message = "Message is required";
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "Phone Number is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.serviceRequired)
      newErrors.serviceRequired = "Service Required is required";
    if (!formData.heardAboutUs)
      newErrors.heardAboutUs = "This field is required";
    if (!formData.consent)
      newErrors.consent =
        "You must give permission to store and process your data";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendToTelegram = async (message) => {
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: TELEGRAM_CHAT_ID,
        text: message,
        parse_mode: "HTML",
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to send message to Telegram");
    }
  };

  const PhoneNumber = ({ number }) => {
    // Remove any non-digit characters from the number
    const cleanNumber = number.replace(/\D/g, '');
  
    return (
      <a href={`tel:${cleanNumber}`} className="text-white hover:text-gray-600 hover:underline">
        {number}
      </a>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const message = `
<b>New Contact Form Submission</b>

<b>Name:</b> ${formData.firstName} ${formData.lastName}
<b>Email:</b> ${formData.email}
<b>Phone:</b> ${formData.phoneNumber}
<b>Service Required:</b> ${formData.serviceRequired}
<b>How they heard about us:</b> ${formData.heardAboutUs}
<b>Subject:</b> ${formData.subject}
<b>Message:</b> ${formData.message}
        `;

        await sendToTelegram(message);

        setSubmitSuccess(true);
        setSubmitError(null);
        // Reset form after successful submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phoneNumber: "",
          subject: "",
          serviceRequired: "",
          heardAboutUs: "",
          consent: false,
        });
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitError("Failed to submit form. Please try again later.");
        setSubmitSuccess(false);
      }
    }
  };

  return (
    <div className="bg-black text-white">
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-12 text-center">
          Contact Fox Smith Associates
        </h1>

        <p className="text-lg mb-6">
          You can reach out to Fox Smith Associates by completing the form below. 
          We will contact you soon.<br></br>
          Or, you can contact us using one of the methods below the form.
        </p>
        <p></p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block mb-1">
                    First Name*
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded bg-gray-800 text-white"
                    required
                  />
                  {errors.firstName && (
                    <p className="text-red-400 text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="lastName" className="block mb-1">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded bg-gray-800 text-white"
                    required
                  />
                  {errors.lastName && (
                    <p className="text-red-400 text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block mb-1">
                  Email Address*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                  required
                />
                {errors.email && (
                  <p className="text-red-400 text-sm mt-1">{errors.email}</p>
                )}
              </div>

              <div>
                <label htmlFor="phoneNumber" className="block mb-1">
                  Phone Number*
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                  required
                />
                {errors.phoneNumber && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="subject" className="block mb-1">
                  Subject*
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                  required
                />
                {errors.subject && (
                  <p className="text-red-400 text-sm mt-1">{errors.subject}</p>
                )}
              </div>

              <div>
                <label htmlFor="serviceRequired" className="block mb-1">
                  Service Required*
                </label>
                <select
                  id="serviceRequired"
                  name="serviceRequired"
                  value={formData.serviceRequired}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                  required
                >
                  <option value="">Select a service</option>
                  <option value="Accounting">Accounting</option>
                  <option value="Tax">Tax</option>
                  <option value="Trust and Estates">Trust and Estates</option>
                  <option value="Company Secretary Services">Company Secretary Services</option>
                </select>
                {errors.serviceRequired && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.serviceRequired}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="heardAboutUs" className="block mb-1">
                  How did you hear about us?*
                </label>
                <input
                  type="text"
                  id="heardAboutUs"
                  name="heardAboutUs"
                  value={formData.heardAboutUs}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                  required
                />
                {errors.heardAboutUs && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.heardAboutUs}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="message" className="block mb-1">
                  Message*
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                  rows="4"
                  required
                ></textarea>
                {errors.message && (
                  <p className="text-red-400 text-sm mt-1">{errors.message}</p>
                )}
              </div>

              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleInputChange}
                    className="mr-2"
                    required
                  />
                  <span>
                    I give permission to store and process my data in accordance
                    with your privacy statement
                  </span>
                </label>
                {errors.consent && (
                  <p className="text-red-400 text-sm mt-1">{errors.consent}</p>
                )}
              </div>

              <button
                type="submit"
                className="bg-white text-black px-6 py-2 rounded hover:bg-gray-200 transition duration-300"
              >
                Submit
              </button>
            </form>

            {submitSuccess && (
              <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
                Thank you for your message. We'll be in touch shortly.
              </div>
            )}

            {submitError && (
              <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                {submitError}
              </div>
            )}
          </div>

          {/* Contact Information */}
          <div>
            <div className="space-y-4 mb-8">
              <div className="flex items-center text-white hover:text-gray-600">
                <Mail className="px-1 mr-2" />
                <span>info@foxsmith.uk</span>
              </div>
              <div className="flex items-center text-white hover:text-gray-600">
                <Phone className="px-1 mr-2" />
                <span>
                  <PhoneNumber number="0044 1628 276 130"/>
                </span>
              </div>
              <div className="flex items-center text-white hover:text-gray-600">
                <MapPin className="px-1 mr-2" />
                <Link
                to="https://www.google.com/maps/place/43+High+St,+Marlow+SL7+1BA/@51.5706497,-0.7752144,17z/data=!3m1!4b1!4m6!3m5!1s0x487689c3f0b71565:0xc5177e8095007cb4!8m2!3d51.5706497!4d-0.7752144!16s%2Fg%2F11cnchpvs5"
                className="tracking-wider text-sm font-semibold inline-block"
              >
                <span>43 High Street, Marlow, SL7 1BA, UK</span>
                </Link>
              </div>
            </div>

            <div className="flex space-x-4 mb-8">
              <SocialMedia />
            </div>

            <div className="mt-8">
              <Link
                to="https://www.google.com/maps/place/43+High+St,+Marlow+SL7+1BA/@51.5706497,-0.7752144,17z/data=!3m1!4b1!4m6!3m5!1s0x487689c3f0b71565:0xc5177e8095007cb4!8m2!3d51.5706497!4d-0.7752144!16s%2Fg%2F11cnchpvs5"
                className="bg-white text-black px-0 py-0 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold inline-block"
              >
                <img
                  src="office-location-in-marlow.jpg"
                  alt="Office Location"
                  className="w-auto h-auto"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
