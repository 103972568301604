import React from 'react';

const StylizedText = ({ text, className = '', color = 'white' }) => {
  const words = text.split(' ');

  return (
    <span
      className={`inline-block tracking-widest font-serif ${
        color === 'black' ? 'text-black' : 'text-white'
      } ${className}`}
    >
      {words.map((word, wordIndex) => (
        <span key={wordIndex} className="font-serif whitespace-wrap mr-3">
          {word.split('').map((letter, letterIndex) => (
            <span
              key={letterIndex}
              className={`font-serif inline-block uppercase ${
                letterIndex === 0 ? 'text-[1.2em]' : ' '
              }`}
              style={{ letterSpacing: '0.39em' }}
            >
              {letter}
            </span>
          ))}
        </span>
      ))}
    </span>
  );
};

export default StylizedText;