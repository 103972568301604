import React from "react";
import { Link } from "react-router-dom";
import foxHeroImage from "../assets/home-hero-fox.jpg";
import StylizedText from "../../src/components/layout/StylizedText";

const services = [
  {
    title: "Accounting & Strategy",
    description: "Partnering with our clients our accounting solutions start with the everyday items, bookkeeping, VAT returns and payroll."
  },
  {
    title: "Tax",
    description: `Navigating the complex, evolving tax landscape can be especially challenging for business owners and individuals managing significant financial responsibilities.`
  },
  {
    title: "Inheritance Tax / Trust & Estates / Family Wealth Preservation Structuring",
    description: "Our tax specialists are both Chartered Tax Advisers (CTA) and members of the Society of Trust & Estate Practitioners (STEP), the global accreditation body for professionals helping families with future planning."
  },
  {
    title: "Non-UK Domiciled Individuals",
    description: "The Autumn 2024 budget has brought significant changes to the tax landscape for UK Resident, Non-UK Domiciled (RND) individuals."
  },
  {
    title: "Offshore Trusts",
    description: "Fox Smith serves as tax advisers for several large offshore Trust Companies, primarily based in the Channel Islands."
  }
];

const Home = () => {
  return (
    <div className="bg-white">
      {/* Hero Section  - Look into the website legal terms and conditions */}
      <>
        <section className="relative bg-black py-16 px-4 md:py-24 lg:py-32">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col items-center text-center">
              <h1 className="text-2xl md:text-3xl lg:text-1xl font-bold mb-3 text-white">
                <StylizedText text="Fox Smith" />
                <StylizedText text="Associates" />
              </h1>
              <div className="w-20 h-0.5 bg-orange-650 mb-6"></div>
              <p className="text-lg md:text-xl mb-8 max-w-2xl text-gray-300 leading-relaxed">
              Personalised Service, Expert Tax solutions
              </p>

              <div className="w-48 h-48 md:w-56 md:h-56 lg:w-64 lg:h-64 mb-8">
                <img
                  src={foxHeroImage}
                  alt="Fox Smith Associates Logo"
                  className=" rounded-full shadow-xl opacity-80"
                />
              </div>

              <Link
                to="/contact"
                className="inline-block bg-transparent border border-orange-650 text-orange-650 px-6 py-2 
                         hover:bg-orange-650 hover:text-black transition duration-300 uppercase 
                         tracking-wider text-sm font-semibold"
              >
                GET IN TOUCH
              </Link>
              <div></div>
            </div>
          </div>
        </section>
      </>
      {/* Key Services Section */}
      <section className="py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-16 text-center">
          Our Key Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="border border-gray-200 p-8 hover:shadow-xl transition duration-300"
            >
              <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-600 mb-6">{service.description}</p>
              <Link
                to="/services"
                className="text-black font-semibold hover:underline"
              >
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>

      {/* About Us Snippet */}
      {/* <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">
              About Fox Smith Associates
            </h2>
            <p className="text-gray-700 mb-12 text-lg leading-relaxed">
            With over 8 years of experience, Fox Smith Associates has been a trusted name in accounting and tax services.
            Our team of expert professionals is committed to delivering exceptional results for our clients, combining deep accounting and tax knowledge with a client-focused approach.
            </p>
            <Link
              to="/about"
              className="text-black font-semibold hover:underline uppercase tracking-wider"
            >
              Learn More About Us
            </Link>
          </div>
        </div>
      </section> */}

      {/* Call to Action */}
      <section className="bg-black text-white py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            As you are Ready to Work with Us
          </h2>
          <p className="text-xl mb-12 max-w-2xl mx-auto">
            We'll discuss how we can help you navigate your accounting, tax and
            business management challenges to successfully achieve your goals.
          </p>
          <Link
            to="/contact"
            className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold"
          >
            Contact Us Today
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
