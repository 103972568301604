// src/components/legal/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      
      <h2 className="text-xl font-semibold mt-6 mb-3">1. Overview</h2>
      <p className="mb-4">These terms govern your use of www.foxsmith.uk and our services.</p>

      <h2 className="text-xl font-semibold mt-6 mb-3">2. Services</h2>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>We provide accounting services to UK and international clients</li>
        <li>All services are subject to separate engagement terms</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">3. Website Use</h2>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>The website is provided "as is"</li>
        <li>We reserve the right to modify or withdraw the website without notice</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">4. Intellectual Property</h2>
      <p className="mb-4">All content on this website belongs to Fox Smith Associates Ltd except the logos who belong to their respective bodies.</p>

      <h2 className="text-xl font-semibold mt-6 mb-3">5. Limitation of Liability</h2>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>We are not liable for any indirect losses</li>
        <li>Our total liability shall not exceed £100</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">6. Governing Law</h2>
      <p className="mb-4">These terms are governed by English law</p>
    </div>
  );
};

export default TermsAndConditions;