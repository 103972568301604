// src/components/legal/LegalPopup.js
import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import CookiePolicy from './CookiePolicy';

const LegalPopup = ({ type, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getContent = () => {
    switch (type) {
      case 'privacy-policy':
        return <PrivacyPolicy />;
      case 'terms-and-conditions':
        return <TermsAndConditions />;
      case 'cookie-policy':
        return <CookiePolicy />;
      default:
        return <p>Legal content not found.</p>;
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="text-sm text-gray-600 hover:text-black transition duration-300"
      >
        {children}
      </button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-2xl w-full max-h-[90vh] overflow-y-auto rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-2">
              {children}
            </Dialog.Title>
            <div className="mt-2">
              <div className="text-sm text-gray-500">
                {getContent()}
              </div>
            </div>

            <button
              type="button"
              className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default LegalPopup;